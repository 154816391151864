
export const verificarToken = (idProcessoSeletivo) => {
    
    const cookie = document.cookie.indexOf("authCandidato")
    
    if(cookie < 0) {
        const urlRedirect = encodeURIComponent(idProcessoSeletivo);
        return window.location.href = `/portal-auth?redirect=${urlRedirect}`
    } else {

        const token = document.cookie.split("; ").find(token => token.startsWith("authCandidato")).split("=")[1];
        
        return token
    }
}

export const tokenApi = () => {

    const cookie = document.cookie.indexOf("authCandidato")
    
    if(cookie < 0) {

        return

    } else {
        const token = document.cookie.split("; ").find(token => token.startsWith("authCandidato")).split("=")[1];

        return token
    }
}

export const Autenticacao = () => {

    const cookie = document.cookie.indexOf("authCandidato")

    if(cookie < 0) {
        return false
    } else {
        return true
    }
}