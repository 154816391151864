import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "../styles";
import { ListaDeProcessoSeletivo } from "../../interfaces/processoSeletivo";
import { inscreverProcessoSeletivo, listarProcessoPorId } from "../../services/processoSeletivo";
import { criptografarDado } from "../../helpers";
import { verificarToken } from "../../middlewares/Token";
import { dadosCandidato } from "../../services/bancoTalentos";
import LogoEmpresa from "../../assets/img/empresa.png"
import Notificacoes from "../Notificacoes";
import PortalVagas from "../../pages/PortalVagas/PortalVagas";

function ModalDetalhesVagas() {
  const [processando, setProcessando] = useState(false)
  const [candidatoInscrito, setCandidatoInscrito] = useState(false)
  const [erroCandidatoInscrito, setErroCandidatoInscrito] = useState(false)
  const [listaDeProcessoSeletivo, setListaDeProcessoSeletivo] = useState<ListaDeProcessoSeletivo | null>(null)
  const [erro, setErro] = useState(false)
  const [situacaoCandidato, setSituacaoCandidato] = useState("")
  const [erroDadosIncompleto, setErroDadosIncompleto] = useState(false)

  const [copiado, setCopiado] = useState(false);

  let idCandidato = localStorage.getItem("idCandidato")
  const { idVaga } = useParams()

  const inscreverProcesso = async (idProcessoSeletivo: number) => {
    setProcessando(true)
    verificarToken(criptografarDado(idProcessoSeletivo.toString()))
    if (situacaoCandidato === "Incompleto") {
      setErroDadosIncompleto(true)
      return setTimeout(() => {
        setErroDadosIncompleto(false)
      }, 5000)
    }
    try {
      const idProcessoSeletivoCript = criptografarDado(idProcessoSeletivo.toString())
      await inscreverProcessoSeletivo(idProcessoSeletivoCript)
      setCandidatoInscrito(true)
      setProcessando(false)
      listarProcesso()
      return setTimeout(() => {
        setCandidatoInscrito(false)
      }, 5000)
    } catch (error: any) {
      setProcessando(false)
      if (error.status === 409) {
        setErroCandidatoInscrito(true)
        setTimeout(() => {
          setErroCandidatoInscrito(false)
        }, 5000)
      }
    }
  }

  const listarProcesso = async () => {
    try {
      if (idVaga) {
        setErro(false)
        const response = await listarProcessoPorId(encodeURIComponent(idVaga.toString()))
        setListaDeProcessoSeletivo(response.data)
      }
    } catch (error) {
      setErro(true)
      setProcessando(false)
    }
  }

  const handleCopiar = (id?: number) => {
    if (id) {
      let idEncoded = encodeURIComponent(id.toString())
      let idCriptografado = criptografarDado(idEncoded)
      const url = `${window.location.origin}/vagas/${idCriptografado}`;
      navigator.clipboard.writeText(url).then(() => {
        setCopiado(true);
        setTimeout(() => setCopiado(false), 3000);
      });
    }
  };

  useEffect(() => {
    const listarDadosCandidato = async () => {
      try {
        const resposta = await dadosCandidato()
        setSituacaoCandidato(resposta.data.situacaoCadastral)
      } catch (error) {
        setProcessando(false)
      }
    }
    listarDadosCandidato()
  }, [])

  useEffect(() => {
    listarProcesso()
  }, [idVaga])

  const SeInscrito = listaDeProcessoSeletivo?.processoInscritos.some(
    (inscrito) => inscrito.idCandidato.toString() === idCandidato?.toString()
  );

  return (
    <>
      <PortalVagas />
      <div className='fundoModal'>
        {erro && <Notificacoes cor={"erro"} texto={"Erro ao listar dados do processo seletivo."} />}
        {erroCandidatoInscrito && <Notificacoes cor={"erro"} texto={"Candidato já inscrito no processo seletivo."} />}
        {candidatoInscrito && <Notificacoes cor={"sucesso"} texto={"Candidato inscrito com sucesso."} />}
        {erroDadosIncompleto && <Notificacoes cor={"erro"} texto={"É necessário que pelo menos a coluna sobreMim, dataNascimento, avatar, endereço e avaliacaoDisc do candidato esteja preenchidos."} />}
        <section className="container-modal-vaga">
          <section className="cabecalho">
            <div className="informacoes-vaga">
              <div className="logo-img">
                <img
                  src={listaDeProcessoSeletivo?.cadastro.avatar ? listaDeProcessoSeletivo.cadastro.avatar : LogoEmpresa}
                  alt=""
                  className="logo-empresa"
                />
              </div>
              <div>
                <h1 className="titulo-vaga">{listaDeProcessoSeletivo?.titulo || "Titulo não disponivel"}</h1>
                <p className="descricao">{listaDeProcessoSeletivo?.cadastro.nomeFantasia || "Nome da empresa não disponivel"}</p>
                <p className="descricao">{listaDeProcessoSeletivo?.localTrabalhoCidade || "Cidade não disponivel"} - {listaDeProcessoSeletivo?.localTrabalhoEstado || "Estado não disponivel"}</p>
              </div>
            </div>
            <div className="tags-vaga">
              <span className="tag">{listaDeProcessoSeletivo?.modalidadeTrabalho || ""}</span>
              <span className="tag">R$ {listaDeProcessoSeletivo?.salario || ""}</span>
              <span className="tag">{listaDeProcessoSeletivo?.regimeContratacao || ""}</span>
              <span className="tag">{listaDeProcessoSeletivo?.formacaoAcademica || ""}</span>
            </div>
            {!SeInscrito && listaDeProcessoSeletivo ?
              <Button isLoading={processando} cor="continuar" type='button' isCancelar={false} onClick={() => { inscreverProcesso(listaDeProcessoSeletivo.id) }}>
                Inscrever-se
              </Button> : <></>}
          </section>
          <section className="conteudo">
            <section className="sobre-vaga">
              <h2 className="subtitulo">Sobre a vaga:</h2>

              <h3 className="subtitulo">Descrição:</h3>
              <p className="descricao">{listaDeProcessoSeletivo?.descricaoCargo || "Descrição não disponivel"}</p>

              <h3 className="subtitulo">Benefícios</h3>
              <p className="descricao">{listaDeProcessoSeletivo?.beneficios || "Beneficios não disponivel"}</p>

              <h3 className="subtitulo">Conhecimentos</h3>
              <div className="container-experiencia">
                <p className="subtitulo">Habilidades desejadas</p>
                <span className="tag-descricao">{listaDeProcessoSeletivo?.requisitos || "Requisitos não disponivel"}</span>
              </div>

              <div className="container-experiencia">
                <p className="subtitulo">Formação Acadêmica</p>
                <span className="tag-descricao">{listaDeProcessoSeletivo?.formacaoAcademica || "Formação Acadêmica não disponivel"}</span>
              </div>

              <div className="container-experiencia">
                <p className="subtitulo">Perfil Comportamental</p>
                <span className="tag-descricao">{listaDeProcessoSeletivo?.perfilComportamental || "Perfil Comportamental não disponivel"}</span>
              </div>

            </section>
            <section>
              <h3 className="subtitulo">Principais atribuições:</h3>
              <p className="descricao">{listaDeProcessoSeletivo?.principaisAtribuicao || "Principais Atribuições não disponivel"}</p>
            </section>
          </section>
          <span className="botoesContainer">
            <Button
              isLoading={processando}
              onClick={() => handleCopiar(listaDeProcessoSeletivo?.id)}
              cor="continuar"
              type="button"
              isCancelar={false}
            >
              {copiado ? "Copiado!" : 'Copiar Link'}
            </Button>
            <Link to="/vagas">
              <Button isLoading={processando} cor="continuar" type='button' isCancelar={false}>
                Fechar
              </Button>
            </Link>
          </span>
        </section>
      </div>
    </>
  );
}

export default ModalDetalhesVagas;