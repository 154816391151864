import nenhumResultado from '../../assets/img/nenhumResultado.png'
import './nenhumresultado.css'

const NenhumResultadoEncontrado = () => {
  return (
    <div className='containerNenhumResultado'>
      <img src={nenhumResultado} alt='' />
      <p>Nenhum Resultado Encontrato</p>
    </div>
  )
}

export default NenhumResultadoEncontrado;